import React, {useEffect, useState} from 'react';
import DragComponent from "./dragComponent";
import axios from "axios";

const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
    }
    if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
            ua
        )
    ) {
        return "mobile";
    }
    return "desktop";
};

export default function QuestionTwo() {
    const [centerValues, setCenterValues] = useState({
        godX: 100,
        godY: 100,
        actionX: 300,
        actionY: 100
    });
    const [isGodDragged, setIsGodDragged] = useState(false)
    const [isActionDragged, setIsActionDragged] = useState(false)
    const [lastDragged, setLastDragged] = useState(null)
    const [selectedText, setSelectedText] = useState('')
    const [r, setR] = useState(100)
    const [showGodOnRight] = useState(JSON.parse(sessionStorage.getItem('showGodOnRight')) ?? Math.random() > 0.5)

    const text1 = {
        yaradan: 'yaradanın',
        tanri: 'tanrının',
        Allah: 'Allah\'ın'
    }

    const text2 = {
        yaradan: 'YARADANI',
        tanri: 'TANRIYI',
        Allah: 'ALLAH\'ı'
    }

    useEffect(() => {
        setSelectedText(window.sessionStorage.getItem('textChoice') ?? 'yaradan')
    }, []);

    async function handleSubmit(e) {
        e.preventDefault();
        const dataToStore = {
            ...Object.assign({ r }, centerValues),
            isGodDragged,
            isActionDragged,
            lastDragged
        }
        sessionStorage.setItem('questionTwo', JSON.stringify(dataToStore));
        sessionStorage.setItem('showGodOnRight', JSON.stringify(showGodOnRight));
        // setIsSubmitted(true);
        await calculateResults();
    }

    async function calculateResults() {
        const firstQuestionData = JSON.parse(sessionStorage.getItem('questionOne'))
        const secondQuestionData = JSON.parse(sessionStorage.getItem('questionTwo'))
        const order = JSON.parse(sessionStorage.getItem('showGodOnRight')) ? 'A-G' : 'G-A'
        // const phoneNumber = JSON.parse(sessionStorage.getItem('phoneNumber'));
        let firstResult = 0
        let secondResult = 0

        const links = {
            yaradan: '231585478653973',
            tanri: '231586667191971',
            Allah: '231586926673974'
        }

        try {
            firstResult = await axios.post('https://tqmwwqlvspy2zasdfip2wi4mma0zxrog.lambda-url.us-east-1.on.aws', {
                godX: firstQuestionData.godX,
                godY: firstQuestionData.godY,
                actionX: firstQuestionData.actionX,
                actionY: firstQuestionData.actionY,
                r: firstQuestionData.r
            })
        } catch (e) {
            console.log('Error occured!', e)
        }

        try {
            secondResult = await axios.post('https://tqmwwqlvspy2zasdfip2wi4mma0zxrog.lambda-url.us-east-1.on.aws', {
                godX: secondQuestionData.godX,
                godY: secondQuestionData.godY,
                actionX: secondQuestionData.actionX,
                actionY: secondQuestionData.actionY,
                r: secondQuestionData.r
            })
        } catch (e) {
            console.log('Error occured!', e)
        }

        const deviceType = getDeviceType()

        window.location = `https://form.jotform.com/${links[selectedText]}?sematest1_result=${firstResult.data}&sematest2_result=${secondResult.data}&st1lastmoveselection=${firstQuestionData.lastDragged === null || firstQuestionData.lastDragged === undefined ? 'None' : firstQuestionData.lastDragged}&st2lastmoveselection=${secondQuestionData.lastDragged === null || secondQuestionData.lastDragged === undefined ? 'None' : secondQuestionData.lastDragged}&st1actionmoveselection=${firstQuestionData.isActionDragged ? 'Yes' : 'No'}&st1godmoveselection=${firstQuestionData.isGodDragged ? 'Yes' : 'No'}&st2actionmoveselection=${secondQuestionData.isActionDragged ? 'Yes' : 'No'}&st2godmoveselection=${secondQuestionData.isGodDragged ? 'Yes' : 'No'}&order=${order}&device=${deviceType}`
    }

    return (
        <div className="question question-two">
            <form onSubmit={handleSubmit}>
                <DragComponent
                    r={r}
                    centerValues={centerValues}
                    showGodOnRight={showGodOnRight}
                    updateToMobile={() => setR(40)}
                    shapeText='Yeryüzündeki Olaylar'
                    mCircleColor='#24c25e95'
                    mTextColor='#24c25e'
                    selectedText={selectedText}
                    updateCenterValues={(newValues, updatedCircle) => {
                        setCenterValues(Object.assign({}, centerValues, newValues))
                        if (updatedCircle === 'God') {
                            setIsGodDragged(true)
                        } else if (updatedCircle === 'Action') {
                            setIsActionDragged(true)
                        }
                        setLastDragged(updatedCircle)
                    }}
                    question={
                        <section>
                            <p className='center'>
                            <b className={'red buyuu'}>ŞEMA - 2</b>
                            </p>
                            <p>
                                <b className={'red'}>Lütfen açıklamayı dikkatlice okuyunuz:</b><br />
                                <p>{`Aşağıdaki şema, ${text1[selectedText]}, yeryüzünde gerçekleşen olayları (kazalar, savaşlar, afetler gibi) hangi oranda etkilediğini göstermek için tasarlanmıştır.`}</p>
                                <ul>
                                    <li>
                                        Yeşil daire <b><span className={"background-green"}>YERYÜZÜNDE GERÇEKLEŞEN OLAYLARI (kazalar, savaşlar, afetler gibi)</span></b> temsil etmektedir.<br />
                                    </li>
                                    <p></p>
                                    <li>
                                        Turuncu daire <b><span className={"background-orange"}>{` ${text2[selectedText]}`}</span></b> temsil etmektedir.<br />
                                    </li>
                                    <p></p>
                                    <li>
                                    İki dairenin <b>kesişim alanının büyüklüğü</b>, <b><span className={"background-grey"}>{` ${text1[selectedText]} yeryüzünde gerçekleşen olayları (kazaları, savaşları, afetleri) etkileme oranını`}</span></b> temsil etmektedir.<br />
                                    <hr></hr>
                                    <ul>
                                        <li>
                                            <b className={'lilliputLand'}><b className={'red'}>Örnek:</b>{` İki dairenin tamamen kesişmesi (üst üste binmesi), ${text1[selectedText]} kazalar, savaşlar, afetler üzerinde tam (%100) etkisi olduğunu gösterir (${selectedText === 'Allah' ? '' : 'bir '}${text1[selectedText]} mutlak müdahalesi ve kontrolüne inanıyorsanız bu yanıt uygundur). `} </b>
                                        </li>
                                        <li>
                                            <b className={'lilliputLand'}><b className={'red'}>Örnek:</b>{` İki dairenin belli bir oranda kesişmesi, ${text1[selectedText]} kazaları, savaşları, afetleri kesişme oranı kadar kısmen etkilediğini gösteri. `} </b>
                                        </li>
                                        <li>
                                            <b className={'lilliputLand'}><b className={'red'}>Örnek:</b>{` İki dairenin ayrık kalması ${text1[selectedText]} kazalar, savaşlar, afetler üzerinde hiçbir etkisi olmadığını gösterir (${selectedText === 'Allah' ? '' : 'bir '}${text1[selectedText]} varlığına inanmıyorsanız ya da varlığına inanıyor ancak asla müdahale etmediğini düşünüyorsanız bu yanıt uygundur).`} </b>
                                        </li>
                                    </ul>
                                    <hr></hr>
                                    </li>
                                    <p></p>
                                </ul>
                                {/*}
                                <p>
                                    <span className={"background-green"}>Yeşil daire dünyada gerçekleşen olayları (kazalar, savaşlar, afetler gibi)</span>,
                                    <span className={"background-blue"}>{`mavi daire ${text2[selectedText]}`}</span> temsil etmektedir.
                                </p>
                                <p>
                                    <span className={"background-yellow"}>{`İki daire arasında kesişen (çakışan) alanın büyüklüğü, ${text1[selectedText]} dünyada gerçekleşen olayları (kazalar, savaşlar, afetler gibi) etkileme oranını temsil etmektedir. `}</span> Yani:
                                </p>
                                */}
                            </p>
                            {/*}
                            <ul>
                                <li>{`
                                İki dairenin tamamen üst üste binmesi dünyada gerçekleşen olayların (kazalar, savaşlar, afetler gibi) tamamen, ${text1[selectedText]} doğrudan etkisiyle gerçekleştiğini gösterir;                                `}</li>
                                <li>{`
                                İki dairenin ayrık kalması dünyada gerçekleşen olaylara (kazalar, savaşlar, afetler gibi) ${text1[selectedText]} doğrudan hiçbir etkisi olmadığını gösterir (${selectedText === 'Allah' ? '' : 'bir '}${text1[selectedText]} varlığına inanmıyorsanız bu yanıt uygundur).
                                `}</li>
                            </ul>
                            */}
                            <p>
                                Aşağıda yeryüzündeki olayları temsil eden yeşil daireyi <b>-üzerine tıklayıp sürükleyerek-</b> hareket ettirebilirsiniz.
                            </p>
                            <b className={'red'}>Görev:</b><br />
                            <ul>
                                <li>
                                    <p>Lütfen <b>yukarıdaki bilgiler doğrultusunda kesişmesi gereken alanın büyüklüğüne karar verip, dairelerin pozisyonlarını ayarlayarak gösteriniz.</b></p>
                                </li>
                                <li>
                                    <p>Bu görevi tamamladığınızda <i>"Kaydet ve İlerle"</i> butonuna basarak bir sonraki sayfaya geçebilirsiniz.</p>
                                </li>
                            </ul>
                            {/*
                            <p>Lütfen <b>yukarıdaki bilgiler doğrultusunda kesişmesi gereken alanın büyüklüğüne karar verip, dairelerin pozisyonlarını ayarlayarak gösteriniz.</b> Bu görevi tamamladığınızda "Kaydet ve İlerle" butonuna basarak bir sonraki sayfaya geçebilirsiniz.</p>
                            */}{/*}
                            <p className='center'>
                            <b className={'red buyuu'}>ŞEMA SORUSU - 2</b>
                        </p>*/}
                        </section>
                    }
                />
                <input type="submit" value="Kaydet ve İlerle" />
            </form>
        </div>
    )
}
