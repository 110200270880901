import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";

export default function LastPage() {
    const [link, setLink] = useState('')

    useEffect(() => {
        calculateResults(window.sessionStorage.getItem('textChoice'))
    }, []);

    async function calculateResults(selectedText) {
        const firstQuestionData = JSON.parse(sessionStorage.getItem('questionOne'))
        const secondQuestionData = JSON.parse(sessionStorage.getItem('questionTwo'))
        const order = JSON.parse(sessionStorage.getItem('showGodOnRight')) ? 'A-G' : 'G-A'
        const phoneNumber = JSON.parse(sessionStorage.getItem('phoneNumber'));
        let firstResult = 0
        let secondResult = 0

        const links = {
            yaradan: '230907598309971',
            tanri: '230212741833043',
            Allah: '230214116524038'
        }

        try {
            firstResult = await axios.post('https://tqmwwqlvspy2zasdfip2wi4mma0zxrog.lambda-url.us-east-1.on.aws', {
                godX: firstQuestionData.godX,
                godY: firstQuestionData.godY,
                actionX: firstQuestionData.actionX,
                actionY: firstQuestionData.actionY,
                r: firstQuestionData.r
            })
        } catch (e) {
            console.log('Error occured!', e)
        }

        try {
            secondResult = await axios.post('https://tqmwwqlvspy2zasdfip2wi4mma0zxrog.lambda-url.us-east-1.on.aws', {
                godX: secondQuestionData.godX,
                godY: secondQuestionData.godY,
                actionX: secondQuestionData.actionX,
                actionY: secondQuestionData.actionY,
                r: secondQuestionData.r
            })
        } catch (e) {
            console.log('Error occured!', e)
        }

        setLink(`https://form.jotform.com/${links[selectedText]}?telefonNo=${phoneNumber}&sematest1_result=${firstResult.data}&sematest2_result=${secondResult.data}&st1lastmoveselection=${firstQuestionData.lastDragged === null || firstQuestionData.lastDragged === undefined ? 'None' : firstQuestionData.lastDragged}&st2lastmoveselection=${secondQuestionData.lastDragged === null || secondQuestionData.lastDragged === undefined ? 'None' : secondQuestionData.lastDragged}&st1actionmoveselection=${firstQuestionData.isActionDragged ? 'Yes' : 'No'}&st1godmoveselection=${firstQuestionData.isGodDragged ? 'Yes' : 'No'}&st2actionmoveselection=${secondQuestionData.isActionDragged ? 'Yes' : 'No'}&st2godmoveselection=${secondQuestionData.isGodDragged ? 'Yes' : 'No'}&order=${order}`)
    }

    return (
        <div className={'son-sayfa'}>
            <h2>Cevaplariniz icin tesekkur ederiz.</h2>
            <iframe src="https://giphy.com/embed/uWlpPGquhGZNFzY90z" width="480" height="351" frameBorder="0"
                    className="giphy-embed" allowFullScreen></iframe>
            {
                link !== '' && <a href={link}>Teste devam etmek icin tiklayiniz</a>
            }
            <p>
                <Link to="/">Basa don</Link>
            </p>
        </div>
    )
}
