import React, {useState, useEffect, useRef} from 'react';
import {Stage, Layer, Circle, Text} from 'react-konva';
import axios from "axios";

export default function DragComponent(props) {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            if (window.innerWidth <= 768 && (prevWindowWidthRef.current === undefined || prevWindowWidthRef.current > 768)) {
                updateToMobile()
                setIsMobile(true)
            }

            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    const {r, question, showGodOnRight, centerValues, updateCenterValues, updateToMobile, shapeText, selectedText, mCircleColor, mTextColor} = props
    const [isDragging, setIsDragging] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const [intersectPercentage, setIntersectPercentage] = useState(0)
    const [textCoordinates, setTextCoordinates] = useState({
        x: centerValues.actionX,
        y: centerValues.actionY,
    });

    const stageWidth = isMobile ? windowSize.width - 32 : windowSize.width * 0.6
    const stageHeight = isMobile ? 200 : windowSize.height * 0.5
    const DISTANCE = 20

    const centerOfGodXDefault = (stageWidth / 2)
    let centerOfActionXDefault = centerOfGodXDefault + ((2 * r) + DISTANCE)

    if (showGodOnRight) {
        centerOfActionXDefault = centerOfGodXDefault - ((2 * r) + DISTANCE)
    }

    const centerOfGodYDefault =  Math.round((stageHeight / 2))
    const centerOfActionYDefault =  Math.round((stageHeight / 2))

    const prevWindowWidthRef = useRef();

    useEffect(() => {
        if (prevWindowWidthRef.current === undefined && windowSize.width !== undefined) {
            if (showGodOnRight) {
                centerOfActionXDefault = centerOfGodXDefault - ((2 * r) + DISTANCE)
            }

            updateCenterValues({
                godX: centerOfGodXDefault,
                godY: centerOfGodYDefault,
                actionX: centerOfActionXDefault,
                actionY: centerOfActionYDefault
            })

            setTextCoordinates({
                x: centerOfActionXDefault - (shapeText.length * 5),
                y: centerOfActionYDefault + r + 10
            })
        }
        prevWindowWidthRef.current = windowSize.width
    }, [windowSize]);

    useEffect(() => {
        calculateResults()
    }, [centerValues])
    async function calculateResults() {
        try {
            const result = await axios.post('https://tqmwwqlvspy2zasdfip2wi4mma0zxrog.lambda-url.us-east-1.on.aws', {
                godX: centerValues.godX,
                godY: centerValues.godY,
                actionX: centerValues.actionX,
                actionY: centerValues.actionY,
                r: r
            })
            setIntersectPercentage(result.data)
        } catch (e) {
            console.log('Error occured!', e)
        }
    }

    return (
        <div className={`circles ${isDragging ? 'drag-active' : ''}`}>
            {question}
            <Stage width={stageWidth} height={stageHeight}>
                <Layer>
                    <Text
                        text={selectedText.toUpperCase()}
                        fontSize={isMobile ? 14 : 18}
                        fill={'orange'}
                        x={centerValues.godX - (selectedText.length * 5)}
                        y={centerValues.godY - r - 20}
                    />
                    <Circle
                        radius={r}
                        x={centerValues.godX}
                        y={centerValues.godY}
                        fill={'orange'}
                        // onDragEnd={(e) => {
                        //     updateCenterValues({
                        //         godX: Math.round(e.target.x()),
                        //         godY: Math.round(e.target.y())
                        //     }, 'God')
                        //     calculateResults()
                        // }}
                    />
                </Layer>
                <Layer>
                    <Text
                        text={shapeText.toUpperCase()}
                        fontSize={isMobile ? 14 : 18}
                        fill={mTextColor}
                        x={textCoordinates.x}
                        y={textCoordinates.y}
                    />
                    <Circle
                        radius={r}
                        x={centerValues.actionX}
                        y={centerValues.actionY}
                        draggable
                        fill={mCircleColor}
                        onDragStart={() => {
                            setIsDragging(true)
                        }}
                        onDragMove={(e) => {
                            setTextCoordinates({
                                x: e.target.x() - (shapeText.length * 5),
                                y: e.target.y() + r + 10
                            })
                        }}
                        onDragEnd={(e) => {
                            setIsDragging(false)
                            updateCenterValues({
                                actionX: Math.round(e.target.x()),
                                actionY: Math.round(e.target.y())
                            }, 'Action')
                        }}
                    />
                </Layer>
                <Layer>
                    <Text
                        text={`%${intersectPercentage}`}
                        fontSize={isMobile ? 14 : 18}
                        fill={'#fb4e4e'}
                        x={centerValues.godX - 10}
                        y={stageHeight - 30}
                    />
                </Layer>
            </Stage>
        </div>
    )
}
