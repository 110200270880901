import * as React from "react";
import { Routes, Route, Link } from "react-router-dom";
import QuestionOne from "./questionOne";
import QuestionTwo from "./questionTwo";
import LastPage from "./lastPage";
import TextChoice from "./textChoice";
import './App.css';

export default function App() {
  return (
      <div>
        <Routes>
            <Route path="/" element={<TextChoice />} />
            {/*<Route path="/secenek" element={<TextChoice />} />*/}
            <Route path="/Q1" element={<QuestionOne />} />
            <Route path="/Q2" element={<QuestionTwo />} />
            <Route path="/son" element={<LastPage />} />

            {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
            <Route path="*" element={<NoMatch />} />
        </Routes>
      </div>
  );
}

function NoMatch() {
  return (
      <div>
        <h2>Nothing to see here!</h2>
        <p>
          <Link to="/">Go to the home page</Link>
        </p>
      </div>
  );
}