import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";

export default function TextChoice() {
    let navigate = useNavigate();
    const [selection, setSelection] = useState('yaradan')

    function handleSubmit(event) {
        event.preventDefault();
        sessionStorage.setItem('textChoice', selection);
        navigate('/Q1');
    }

    return (
        <div className='project-explanation'>
            <p>
            İstanbul Üniversitesi, İstanbul Tıp Fakültesi, Nöroloji Anabilim Dalı öğretim üyesi Prof. Dr. İbrahim Hakan Gürvit’in danışmanlığında (sorumluluğunda) İstanbul Üniversitesi, Aziz Sancar Deneysel Tıp Araştırma Enstitüsü, Sinirbilim Anabilim Dalı İleri Nörolojik Bilimler doktora programında Emel Gençer tarafından yürütülen, “Sağlıklı Bireylerde Örtük Bağlamsal Öğrenme Becerisindeki Değişkenliklerin İnanışlardaki Bireysel Farklılaşma Ve Gen Polimorfizmleri İle İlişkisi” konu başlıklı doktora tez çalışmamız İstanbul Üniversitesi Tıp Fakültesi Klinik Araştırmalar Etik Kurul Onayına sahiptir ve İstanbul Üniversitesi Bilimsel Araştırma Projeleri Koordinasyon Birimi tarafından desteklenmektedir. Proje numarası: TDK-2023-40116. <br /><br />

                Çalışmamıza katılmaya gönüllü olduğunuz için teşekkür ederiz. Bu çalışmada karşılaşacağınız durumlar ve sizden yapmanız beklenenler aşağıdaki gibidir:
            </p>
            <ul>
                <li>Aşağıdaki soruya cevap vererek bir sonraki sayfaya ilerleyeceksiniz.</li>
                <li>Sonraki sayfalarda 2 tane benzer şema sorusuyla karşılaşacaksınız. Her bir şema sorusunun üst kısmında bulunan yazılı açıklamayı ve yönlendirmeyi dikkatlice okuyarak şema sorularını cevaplamanız gerekmektedir. </li>
                <li>Şema sorularını tamamladıktan sonra açılan yeni sayfada çalışmamız hakkında detaylı bilgi veren bir açıklama metni ve gönüllü katılımınızı belirtebileceğiniz bir onay kutusunu göreceksiniz.</li>
                <li>Devamında sırasıyla 7, 5, 10 ve 21 soruluk 4 anketle karşılaşacaksınız. Her bir anketi samimi bir şekilde yanıtlamanız beklenmektedir.</li>
                {/*
                <li>Daha sonra sırasıyla 3 anketin İngilizce formlarıyla karşılaşacaksınız. Anketlerin İngilizce formlarını da samimi bir şekilde yanıtlamanız beklenmektedir.</li>
    */}
                <li>Son kısımda yaşınız, eğitim durumunuz, mesleğiniz gibi soruların bulunduğu kişisel bilgi formunu doldurmanız istenecektir.</li>
            </ul>
                <p><i><b>NOT:</b> Çalışmamızın bu aşamasını tamamlayan ve iletişim bilgisini bizimle paylaşan katılımcılarımız arasından 84 gönüllü ikinci aşamaya davet edilecektir. İkinci aşamayı tamamlayan katılımcılarımıza 200 TL ödeme yapılacaktır.</i></p>
                <p>Çalışmamıza ayırdığınız zaman ve katkılarınız için şimdiden teşekkür ederiz.</p>
            <form onSubmit={handleSubmit}>
                <p>Günlük konuşma dilinde ihtiyaç duyduğunuz durumlarda aşağıdaki üç ifadeden hangisini kullanmayı tercih edersiniz?</p>
                <div className="choices-option">
                    <label>
                        <input type="radio" value="yaradan" checked={selection === 'yaradan'} onChange={() => setSelection('yaradan')} />
                        Yaradan
                    </label>
                </div>
                <div className="choices-option">
                    <label>
                        <input type="radio" value="Allah" checked={selection === 'Allah'} onChange={() => setSelection('Allah')} />
                        Allah
                    </label>
                </div>
                <div className="choices-option">
                    <label>
                        <input type="radio" value="tanri" checked={selection === 'tanri'} onChange={() => setSelection('tanri')} />
                        Tanrı
                    </label>
                </div>
                <input type="submit" value="Kaydet ve İlerle" />
            </form>
        </div>
    )
}
