import React, {useEffect, useState} from 'react';
import DragComponent from "./dragComponent";
import {useNavigate} from "react-router-dom";

export default function QuestionOne() {
    let navigate = useNavigate();

    const [centerValues, setCenterValues] = useState({
        godX: 100,
        godY: 100,
        actionX: 300,
        actionY: 100
    });
    const [isGodDragged, setIsGodDragged] = useState(false)
    const [isActionDragged, setIsActionDragged] = useState(false)
    const [lastDragged, setLastDragged] = useState(null)
    const [selectedText, setSelectedText] = useState('')
    const [r, setR] = useState(100)
    const [showGodOnRight] = useState(Math.random() > 0.5)

    const text1 = {
        yaradan: 'yaradanın',
        tanri: 'tanrının',
        Allah: 'Allah\'ın'
    }

    const text2 = {
        yaradan: 'YARADANI',
        tanri: 'TANRIYI',
        Allah: 'ALLAH\'ı'
    }

    useEffect(() => {
        setSelectedText(window.sessionStorage.getItem('textChoice') ?? 'yaradan')
    }, []);

    async function handleSubmit(e) {
        e.preventDefault();
        const dataToStore = {
            ...Object.assign({ r }, centerValues),
            isGodDragged,
            isActionDragged,
            lastDragged: lastDragged ?? null
        }
        sessionStorage.setItem('questionOne', JSON.stringify(dataToStore));
        sessionStorage.setItem('showGodOnRight', JSON.stringify(showGodOnRight));
        navigate('/Q2')
    }

    return (
        <div className="question question-one">
            <form onSubmit={handleSubmit}>
                <DragComponent
                    r={r}
                    centerValues={centerValues}
                    showGodOnRight={showGodOnRight}
                    updateToMobile={() => setR(40)}
                    shapeText='Eylemlerimiz'
                    mCircleColor='#0069ff95'
                    mTextColor='#0069ff'
                    selectedText={selectedText}
                    updateCenterValues={(newValues, updatedCircle) => {
                        setCenterValues(Object.assign({}, centerValues, newValues))
                        if (updatedCircle === 'God') {
                            setIsGodDragged(true)

                        } else if (updatedCircle === 'Action') {
                            setIsActionDragged(true)
                        }
                        setLastDragged(updatedCircle)
                    }}
                    question={
                        <section>
                            <p className='center'>
                                <b className={'red buyuu'}>ŞEMA - 1</b>
                            </p>
                            <p>
                                <b className={'red'}>Lütfen açıklamayı dikkatlice okuyunuz:</b><br />
                                <p>{`Aşağıdaki şema, ${text1[selectedText]} kendi eylemlerinizi hangi oranda etkilediğini göstermek için tasarlanmıştır.`}</p>
                                <ul>
                                    <li>
                                        Mavi daire <b><span className={"background-blue"}>KENDİ EYLEMLERİMİZİ (karar ve davranışlarımızı)</span></b> temsil etmektedir.<br />
                                    </li>
                                    <p></p>
                                    <li>
                                        Turuncu daire <b><span className={"background-orange"}>{` ${text2[selectedText]}`}</span></b> temsil etmektedir.<br />
                                    </li>
                                    <p></p>
                                    <li>
                                    İki dairenin <b>kesişim alanının büyüklüğü</b>, <b><span className={"background-grey"}>{` ${text1[selectedText]} eylemlerimizi etkileme oranını`}</span></b> temsil etmektedir.<br />
                                    <hr></hr>
                                    <ul>
                                        <li>
                                            <b className={'lilliputLand'}><b className={'red'}>Örnek:</b>{` İki dairenin tamamen kesişmesi (üst üste binmesi), ${text1[selectedText]} eylemlerimiz (karar ve davranışlarımız) üzerinde tam (%100) etkisi olduğunu gösterir (${selectedText === 'Allah' ? '' : 'bir '}${text1[selectedText]} mutlak müdahalesi ve kontrolüne inanıyorsanız bu yanıt uygundur). `} </b>
                                        </li>
                                        <li>
                                            <b className={'lilliputLand'}><b className={'red'}>Örnek:</b>{` İki dairenin belli bir oranda kesişmesi, ${text1[selectedText]} eylemlerimizi (karar ve davranışlarımızı) kesişme oranı kadar kısmen etkilediğini gösterir. `} </b>
                                        </li>
                                        <li>
                                            <b className={'lilliputLand'}><b className={'red'}>Örnek:</b>{` İki dairenin ayrık kalması ${text1[selectedText]} eylemlerimiz (karar ve davranışlarımız) üzerinde hiçbir etkisi olmadığını gösterir (${selectedText === 'Allah' ? '' : 'bir '}${text1[selectedText]} varlığına inanmıyorsanız ya da varlığına inanıyor ancak asla müdahale etmediğini düşünüyorsanız bu yanıt uygundur).`} </b>
                                        </li>
                                    </ul>
                                    <hr></hr>
                                    </li>
                                    <p></p>
                                </ul>

                                {/*<p>
                                    <span className={"background-green"}>Yeşil daire eylemlerimizi (karar ve davranışlarımızı)</span> temsil etmektedir.
                                </p>
                                <p>
                                    <span className={"background-blue"}>{`Mavi daire ${text2[selectedText]}`}</span> temsil etmektedir.
                                </p>
                                <p>
                                    <span className={"background-yellow"}>{`İki daire arasında kesişen (çakışan) alanın büyüklüğü, ${text1[selectedText]} eylemlerimizi (karar ve davranışlarımızı) etkileme oranını temsil etmektedir.`}</span>
                                </p>
                    */}
                            </p>
                            {/*}
                            <ul>
                                <li>{`
                                İki dairenin tamamen üst üste binmesi eylemlerimizin (karar ve davranışlarımızın) tamamen, ${text1[selectedText]} doğrudan etkisiyle gerçekleştiğini gösterir;
                                `}</li>
                                <li>{`
                                İki dairenin ayrık kalması eylemlerimize (karar ve davranışlarımıza) ${text1[selectedText]} doğrudan hiçbir etkisi olmadığını gösterir (${selectedText === 'Allah' ? '' : 'bir '}${text1[selectedText]} varlığına inanmıyorsanız bu yanıt uygundur).`}</li>
                            </ul>*/}
                            <p>
                                Aşağıda eylemlerimizi temsil eden mavi daireyi <b>-üzerine tıklayıp sürükleyerek-</b> hareket ettirebilirsiniz.
                            </p>
                            <b className={'red'}>Görev:</b><br />
                            <ul>
                                <li>
                                    <p>Lütfen <b>yukarıdaki bilgiler doğrultusunda kesişmesi gereken alanın büyüklüğüne karar verip, dairelerin pozisyonlarını ayarlayarak gösteriniz.</b></p>
                                </li>
                                <li>
                                    <p>Bu görevi tamamladığınızda <i>"Kaydet ve İlerle"</i> butonuna basarak bir sonraki sayfaya geçebilirsiniz.</p>
                                </li>
                            </ul>
                            {/*
                            <p>Lütfen <b>yukarıdaki bilgiler doğrultusunda kesişmesi gereken alanın büyüklüğüne karar verip, dairelerin pozisyonlarını ayarlayarak gösteriniz.</b> Bu görevi tamamladığınızda "Kaydet ve İlerle" butonuna basarak bir sonraki sayfaya geçebilirsiniz.</p>
                            */}{/*
                            <p className='center'>
                            <b className={'red buyuu'}>ŞEMA - 1</b>
                            </p>*/}
                        </section>
                    }
                />
                <input type="submit" value="Kaydet ve İlerle" />
            </form>
        </div>
    )
}
